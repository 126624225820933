export default [
  {
    name: 'months.january',
    value: 1,
  },
  {
    name: 'months.february',
    value: 2,
  },
  {
    name: 'months.march',
    value: 3,
  },
  {
    name: 'months.april',
    value: 4,
  },
  {
    name: 'months.may',
    value: 5,
  },
  {
    name: 'months.june',
    value: 6,
  },
  {
    name: 'months.july',
    value: 7,
  },
  {
    name: 'months.august',
    value: 8,
  },
  {
    name: 'months.september',
    value: 9,
  },
  {
    name: 'months.october',
    value: 10,
  },
  {
    name: 'months.november',
    value: 11,
  },
  {
    name: 'months.december',
    value: 12,
  },
]
