<template>
  <location-bar
    :with-title="false"
    :flex-size="flexSize"
    :with-filters="filters"
    @selected-region="regionsFilter"
    @selected-country="countriesFilter"
    @selected-district="districtsFilter"
  >
    <div class="flex xs12 md5">
      <va-select
        v-model="selectedMonth"
        :label="$t('layout.form.monthInput')"
        :no-options-text="$t('layout.empty')"
        :disabled="loading"
        :options="monthsList"
        text-by="name"
        key-by="value"
        searchable
      />
    </div>
    <div class="flex xs12 md5">
      <va-input
        color="info"
        v-model="form.year.value"
        :disabled="loading"
        :label="$t('layout.form.yearInput')"
        :error="!!form.year.errors.length"
        :error-messages="translatedErrors(form.year.errors)"
        @blur="validate('year')"
      />
    </div>
    <div class="flex xs12 md2">
      <va-button
        color="primary"
        :disabled="loading"
        @click.prevent="submit"
      >
        <text-loading :loading="loading">{{ $t('reports.generate.button') }}</text-loading>
      </va-button>
    </div>
  </location-bar>
</template>
<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
import Months from '@/data/Months'
const LocationBar = () => import(/* webpackPrefetch: true */ './Location')

export default {
  name: 'monthly-bar',
  components: {
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    selection: {
      type: String,
      default: null,
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      monthsList: [],
      filters: [],
      selectedMonth: '',
      selectedRegion: null,
      selectedCountry: null,
      selectedDistrict: null,
      form: {
        year: {
          value: (new Date()).getFullYear(),
          validate: {
            required: true,
            maxValue: (new Date()).getFullYear() + 1,
            minValue: 2000,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    flexSize () {
      let def = 'md4'
      if (Array.isArray(this.filters)) {
        switch (this.filters.length) {
          case 1: def = 'md12'; break
          case 2: def = 'md6'; break
        }
      }
      return def
    },
  },
  watch: {
    currentLocale (val) {
      this.getMonths()
    },
  },
  created () {
    let route = []
    switch (this.selection) {
      case 'region':
        if (this.currentUser.can('Regions', 'index')) {
          route = ['regions']
        }
        break
      case 'country':
        if (this.currentUser.can('Countries', 'index')) {
          route = ['regions', 'countries']
        }
        break
      case 'district':
        if (this.currentUser.can('Districts', 'index')) {
          route = ['regions', 'countries', 'districts']
        }
        break
    }

    this.getMonths()
    this.filters = route.slice(0)
  },
  methods: {
    getMonths () {
      const months = []
      for (const m of Months) {
        const month = {
          name: this.$t(m.name),
          value: m.value,
        }
        months.push(month)
      }

      this.monthsList = months.slice(0)
    },
    regionsFilter (region) {
      this.selectedRegion = region
    },
    countriesFilter (country) {
      this.selectedCountry = country
    },
    districtsFilter (district) {
      this.selectedDistrict = district
    },
    submit () {
      this.validateAll()
      if (!this.formReady) return

      const generate = {}
      const year = this.getFormValue('year').toString()
      if (this.selectedMonth) {
        generate.date = `${year}-${this.selectedMonth.value}-01`
      }
      if (this.selectedRegion) {
        generate.region = this.selectedRegion.id
      }
      if (this.selectedCountry) {
        generate.country = this.selectedCountry.id
      }
      if (this.selectedDistrict) {
        generate.district = this.selectedDistrict.id
      }

      this.$emit('submit', generate)
    },
  },
}
</script>
